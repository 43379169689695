<template>
  <div class="topupdetail" v-wechat-title="$route.meta.title">
    <navigation :msg="msg"></navigation>
    <div class="buclass">
      <button id="bu1" @click="tap1" :class="{ orange: num == 1 }">
        充值明细
      </button>
      <button id="bu2" @click="tap2" :class="{ orange: num == 2 }">
        消费明细
      </button>
    </div>
    <van-sticky v-if="num == 2">
      <van-tabs @change="changetab" v-model="active">
        <van-tab title="委托方消费"></van-tab>
        <van-tab title="候选人消费"></van-tab>
      </van-tabs>
    </van-sticky>
    <div @click="clickpay(item)" class="" v-for="(item, index) in list" :key="index">
      <!-- <div class="servicecharge" v-if="num === 1 && item.edit_balance > 0">
        <div class="service">充值{{ item.edit_balance }}元</div>
        <div class="ppp">
          <div class="time">
            <img src="../../assets/time.png" alt="" />
          </div>
          <div class="times">{{ item.add_time }}</div>
          <div class="money">+{{ item.edit_balance }}</div>
        </div>
      </div> -->
      <div class="fuwufclass">
        <div class="moneyclass">
          <div>
            {{ num == 1 ? (item.edit_balance+'').indexOf('-')!=-1?'扣除':"充值" : "消费"
            }}{{
              (item.edit_balance + "").indexOf("-") != -1
                ? item.edit_balance.substr(1, item.edit_balance.length - 1)
                : item.edit_balance
            }}元
          </div>
          <div :style='item.type == 1
                ? "background:#5FCD86"
                : item.type == 2
                ? "background:#32BEEE"
                : item.type == 3
                ? "background:#FF6744"
                : item.type == 4
                ? "background:#FF9E0E"
                : item.type == 5
                ? "background:#FF5151"
                : item.type == 6
                ? "background:#FF9E0E"
                : item.type == 7
                ? "background:#32BEEE"
                : item.type == 8
                ? "background:#FF5151"
                : item.type == 9
                ? "background:#5FCD86"
                : item.type == 10
                ? "background:#FF9E0E"
                : item.type == 11
                ? "background:#32BEEE"
                : item.type == 12
                ? "background:#FF5151"
                : item.type == 13
                ? "background:#5FCD86"
                : ""' class="biaoclass">
            {{
              item.type == 1
                ? "初始化赠送"
                : item.type == 2
                ? "请求报告"
                : item.type == 3
                ? "充值"
                : item.type == 4
                ? "管理员分配"
                : item.type == 5
                ? "余额购买套餐"
                : item.type == 6
                ? "分享简历"
                : item.type == 7
                ? "下载简历"
                : item.type == 8
                ? "背调退款"
                : item.type == 9
                ? "查看员工评论"
                : item.type == 10
                ? "收益"
                : item.type == 11
                ? "预览简历"
                : item.type == 12
                ? "取消简历分享"
                : item.type == 13
                ? "完善简历"
                : ""
            }}
          </div>
        </div>
        <div class="timemoneyclass">
          <div class="timeclass">
            <img src="../../assets/time.png" alt="" />
            <div>{{ item.add_time }}</div>
          </div>
          <div class="addmoney">
            {{ num == 1 ? (item.edit_balance+'').indexOf('-')!=-1?'':"+" : active == 1 ? "-" : ""
            }}{{ item.edit_balance }}元
          </div>
        </div>
      </div>
      <!-- <div class="fuwufclass" v-if="num === 2 && item.edit_balance < 0">
        <div class="moneyclass">
          消费{{ item.edit_balance.replace("-", "") }}元
        </div>
        <div class="timemoneyclass">
          <div class="timeclass">
            <img src="../../assets/time.png" alt="" />
            <div>{{ item.add_time }}</div>
          </div>
          <div class="addmoney">{{ item.edit_balance }}元</div>
        </div>
      </div> -->
    </div>
    <loading @loaddom="loaddom" ref="loades"></loading>
    <div class="nomsg"></div>
  </div>
</template>

<script>
import loading from "../../components/loading2/index";
export default {
  components: {
    loading,
  },
  data() {
    return {
      msg: "账户明细",
      num: 1,
      list: [],
      pageno: 0,
      active: "0",
    };
  },
  created() {
    if(this.$route.query.num){
      this.num = this.$route.query.num;
    }
  },
  methods: {
    //点击充值/消费详情
    clickpay(msg){
      
    },
    changetab(e) {
      if (e == 0) {
        this.tap2("no");
      } else {
        this.tap3();
      }
    },
    loaddom() {
      if (this.active == 2) {
        this.$refs.loades
          .loadhttpes("/firm/v1/cost/details1", {
            reqType: "cost",
            pageno: ++this.pageno,
          })
          .then((res) => {
             
            this.list = res.arraymsg;
          });
      } else {
        this.$refs.loades
          .loadhttpes("/firm/v1/cost/details1", {
            reqType: "cost",
            type: this.num == 1 ? "1" : this.active == 1 ? "2" : "",
            page: ++this.pageno,
          })
          .then((res) => {
             
            this.list = res.arraymsg;
          });
      }
    },
    // 充值明细
    tap1() {
      this.pageno = 0;
       
      if (arguments[0] == "no") {
      } else {
        this.num = 1;
      }

      this.$refs.loades.empty();
      this.$refs.loades
        .loadhttpes("/firm/v1/cost/details1", {
          reqType: "cost",
          type: "1",
          page: ++this.pageno,
        })
        .then((res) => {
           
          this.list = res.arraymsg;
        });
    },
    // 消费明细
    tap2() {
      this.pageno = 0;
      this.num = 2;
      this.$refs.loades.empty();
      this.$refs.loades
        .loadhttpes("/firm/v1/cost/details1", {
          reqType: "cost",
          type: "2",
          page: ++this.pageno,
        })
        .then((res) => {
          this.list = res.arraymsg;
        });
    },
    // 消费明细(候选人)
    tap3() {
      this.pageno = 0;
      this.$refs.loades.empty();
      this.$refs.loades
        .loadhttpes("/firm/v1/cost/getCostDetail", {
          reqType: "cost",
          pageno: ++this.pageno,
        })
        .then((res) => {
          this.list = res.arraymsg;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.nomsg {
  height: 0.3rem;
}
.biaoclass {
  font-size: 0.2rem;
  padding: 0.05rem 0.1rem;
  color: #ffffff;
  border-radius: 0.1rem;
  margin-left: 0.2rem;
  background-color: #fe5800;
}
.addmoney {
  font-size: 0.34rem;
  line-height: 0.48rem;
  color: #fe5800;
  font-weight: 500;
}
.timeclass img {
  width: 0.19rem;
  height: 0.19rem;
  margin-right: 0.14rem;
}
.timeclass {
  display: flex;
  align-items: center;
}
.timemoneyclass {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.22rem;
  font-size: 0.24rem;
  font-weight: 400;
  color: #616b80;
  line-height: 0.32rem;
}
.moneyclass {
  font-size: 0.3rem;
  list-style: 0.34rem;
  color: #141f33;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.fuwufclass {
  margin: 0.2rem 0.3rem;
  background-color: #ffffff;
  border-radius: 0.16rem;
  padding: 0.38rem 0.3rem;
}
.topupdetail {
  background-color: #f7f7f7;
}
.buclass {
  display: flex;
  box-sizing: border-box;
  margin-bottom: 0.31rem;
  padding: 0.24rem 0.33rem 0.24rem 0.33rem;
  justify-content: center;
  font-size: 0.32rem;
  overflow: hidden;
  height: 1.18rem;
  font-weight: bold;
  background-color: #ffffff;
}
.orange {
  background-color: #f55613 !important;
  color: #ffffff !important;
}
.ppp {
  display: flex;
  align-items: center;
}
#bu1,
#bu2 {
  border: none;
  width: 50%;
}
#bu1 {
  background-color: rgba(245, 86, 19, 0.39);
  color: #f55613;
  border-top-left-radius: 0.6rem;
  border-bottom-left-radius: 0.6rem;
}
#bu2 {
  background-color: rgba(245, 86, 19, 0.39);
  color: #f55613;
  border-top-right-radius: 0.6rem;
  border-bottom-right-radius: 0.6rem;
}
.orderform {
  width: 100%;
  padding: 0 0.3rem;
  box-sizing: border-box;
  .servicecharge {
    position: relative;
    margin: 0 auto;
    width: 100%;
    height: 1.66rem;
    background: #ffffff;
    border-radius: 0.16rem;
    margin-bottom: 0.14rem;
    .service {
      position: absolute;
      left: 0.3rem;
      top: 0.38rem;
      width: 2.36rem;
      height: 0.34rem;
      font-size: 0.3rem;
      color: #141f33;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #141f33;
      line-height: 0.34rem;
    }
    .time {
      position: absolute;
      left: 0.35rem;
      top: 1rem;
      width: 0.21rem;
      height: 0.21rem;
      img {
        vertical-align: top;
        width: 100%;
        height: 100%;
      }
    }
    .times {
      position: absolute;
      top: 0.94rem;
      left: 0.68rem;
      // width: 1.35rem;
      height: 0.32rem;
      font-size: 0.24rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #616b80;
      line-height: 0.32rem;
    }
    .money {
      position: absolute;
      top: 0.91rem;
      right: 0.31rem;
      width: 0.8rem;
      height: 0.33rem;
      font-size: 0.34rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #fe5800;
      line-height: 0.48rem;
    }
  }
  .consumption {
    position: relative;
    margin: 0 auto;
    width: 100%;
    height: 1.66rem;
    background: #ffffff;
    border-radius: 0.16rem;
    margin-bottom: 0.14rem;
    .service {
      position: absolute;
      left: 0.3rem;
      top: 0.38rem;
      width: 3.15rem;
      height: 0.34rem;
      font-size: 0.3rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #141f33;
      line-height: 0.34rem;
    }
    .time {
      position: absolute;
      left: 0.35rem;
      top: 1rem;
      width: 0.21rem;
      height: 0.21rem;

      img {
        vertical-align: top;

        width: 100%;
        height: 100%;
      }
    }
    .times {
      position: absolute;
      top: 0.94rem;
      left: 0.68rem;
      // width: 1.35rem;
      height: 0.32rem;
      font-size: 0.24rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #616b80;
      line-height: 0.32rem;
    }
    .money {
      position: absolute;
      top: 0.91rem;
      right: 0.31rem;
      width: 0.8rem;
      height: 0.33rem;
      font-size: 0.34rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #fe5800;
      line-height: 0.48rem;
    }
  }
}
</style>
