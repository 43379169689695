var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"wechat-title",rawName:"v-wechat-title",value:(_vm.$route.meta.title),expression:"$route.meta.title"}],staticClass:"topupdetail"},[_c('navigation',{attrs:{"msg":_vm.msg}}),_c('div',{staticClass:"buclass"},[_c('button',{class:{ orange: _vm.num == 1 },attrs:{"id":"bu1"},on:{"click":_vm.tap1}},[_vm._v(" 充值明细 ")]),_c('button',{class:{ orange: _vm.num == 2 },attrs:{"id":"bu2"},on:{"click":_vm.tap2}},[_vm._v(" 消费明细 ")])]),(_vm.num == 2)?_c('van-sticky',[_c('van-tabs',{on:{"change":_vm.changetab},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('van-tab',{attrs:{"title":"委托方消费"}}),_c('van-tab',{attrs:{"title":"候选人消费"}})],1)],1):_vm._e(),_vm._l((_vm.list),function(item,index){return _c('div',{key:index,on:{"click":function($event){return _vm.clickpay(item)}}},[_c('div',{staticClass:"fuwufclass"},[_c('div',{staticClass:"moneyclass"},[_c('div',[_vm._v(" "+_vm._s(_vm.num == 1 ? (item.edit_balance+'').indexOf('-')!=-1?'扣除':"充值" : "消费")+_vm._s((item.edit_balance + "").indexOf("-") != -1 ? item.edit_balance.substr(1, item.edit_balance.length - 1) : item.edit_balance)+"元 ")]),_c('div',{staticClass:"biaoclass",style:(item.type == 1
              ? "background:#5FCD86"
              : item.type == 2
              ? "background:#32BEEE"
              : item.type == 3
              ? "background:#FF6744"
              : item.type == 4
              ? "background:#FF9E0E"
              : item.type == 5
              ? "background:#FF5151"
              : item.type == 6
              ? "background:#FF9E0E"
              : item.type == 7
              ? "background:#32BEEE"
              : item.type == 8
              ? "background:#FF5151"
              : item.type == 9
              ? "background:#5FCD86"
              : item.type == 10
              ? "background:#FF9E0E"
              : item.type == 11
              ? "background:#32BEEE"
              : item.type == 12
              ? "background:#FF5151"
              : item.type == 13
              ? "background:#5FCD86"
              : "")},[_vm._v(" "+_vm._s(item.type == 1 ? "初始化赠送" : item.type == 2 ? "请求报告" : item.type == 3 ? "充值" : item.type == 4 ? "管理员分配" : item.type == 5 ? "余额购买套餐" : item.type == 6 ? "分享简历" : item.type == 7 ? "下载简历" : item.type == 8 ? "背调退款" : item.type == 9 ? "查看员工评论" : item.type == 10 ? "收益" : item.type == 11 ? "预览简历" : item.type == 12 ? "取消简历分享" : item.type == 13 ? "完善简历" : "")+" ")])]),_c('div',{staticClass:"timemoneyclass"},[_c('div',{staticClass:"timeclass"},[_c('img',{attrs:{"src":require("../../assets/time.png"),"alt":""}}),_c('div',[_vm._v(_vm._s(item.add_time))])]),_c('div',{staticClass:"addmoney"},[_vm._v(" "+_vm._s(_vm.num == 1 ? (item.edit_balance+'').indexOf('-')!=-1?'':"+" : _vm.active == 1 ? "-" : "")+_vm._s(item.edit_balance)+"元 ")])])])])}),_c('loading',{ref:"loades",on:{"loaddom":_vm.loaddom}}),_c('div',{staticClass:"nomsg"})],2)}
var staticRenderFns = []

export { render, staticRenderFns }